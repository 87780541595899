import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {Box, Modal, Stack, Typography, styled} from '@mui/material'

import breakpoints from '../../../../dashboard/styles/abstracts/breakpoints.module.scss'
import Button from '../../button/Button'
import CloseIcon from '../../../core/svg_icons/icons/CloseIcon'
import colors from '../../../../dashboard/styles/abstracts/colors.module.scss'

/* Override any of the following styles using the MuiModal-root class */

const StyledBox = styled(Box)(
  () => `
    align-items: center;
    background: ${colors.boxBackground};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: 134px auto 0;
    max-width: 480px;
    padding: 32px;
    width: 90%;
    @media (min-width: ${breakpoints.mobileMd}) {
      padding: 40px;
    }
  `
)

const StyledH2Typography = styled(Typography)(
  ({theme}) => `
    &.heading {
      color: ${theme.palette.error.main};
      font-size: 1.3125rem;
      font-weight: 600;
      line-height: 109.524%;
    }
    margin: 12px 0 16px;
  `
)

const StyledBodyTypography = styled(Typography)(
  ({theme}) => `
    &.body-text {
      line-height: 106.667%;
      margin-bottom: 24px;
    }
    color: ${theme.palette.primary.bodyText};
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 0.9375rem;
    font-weight: 300;
  `
)

const StyledConfirmationModal = props => {
  const {bodyText, headingText, onClose, onConfirmation, open} = props
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false)

  useEffect(() => {
    setAreButtonsDisabled(false)
  }, [open])

  const handleCancelClick = () => {
    setAreButtonsDisabled(true)
    onClose()
  }

  const handleExitClick = () => {
    setAreButtonsDisabled(true)
    onConfirmation()
  }

  return (
    <Modal
      onClose={reason => (reason === 'backdropClick' ? null : onClose)}
      open={open}
      sx={{
        overflowX: 'hidden',
        zIndex: '1302'
      }}>
      <StyledBox>
        <CloseIcon
          height='12'
          onClick={onClose}
          style={{alignSelf: 'flex-end', cursor: 'pointer'}}
          width='12'
        />
        <StyledH2Typography className='heading' variant='h2'>
          {headingText}
        </StyledH2Typography>
        <StyledBodyTypography className='body-text' variant='body1'>
          {bodyText}
        </StyledBodyTypography>
        <Stack direction='row' gap={2}>
          <Button
            color='cancel'
            disabled={areButtonsDisabled}
            onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button
            color='error'
            disabled={areButtonsDisabled}
            onClick={handleExitClick}>
            Exit
          </Button>
        </Stack>
      </StyledBox>
    </Modal>
  )
}

StyledConfirmationModal.propTypes = {
  bodyText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default StyledConfirmationModal
